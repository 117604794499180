import React from "react";
import Pagination from "react-js-pagination";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";

import { useSelector } from "react-redux";
import RegisterService from "../../../services/register.service";
import Select from "react-select";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { ipAdd, quizSearch } from "../../../schema";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

export default function ManageRegisteredIps() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);

  const ipList = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.listRegisteredIps();

      if (responce.status === 200) {
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.id,
            ip_address: value.ip_address,
            register_status: value.register_status,
          });
        });

        settableData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      ipList();
    }
  }, [adminAccessToken]);

  const addIp = async (values, action) => {
    setLoading(true);
    try {
      await AdminListService.addNewIp(values);

      toast.success("IP Address has been added successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
      ipList();
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.warn(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const delAcc = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const response = await AdminListService.deleteIpAddress(delId);

          toast.success("Deleted Successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          ipList();
        } catch (err) {
          setLoading(false);
          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.warn(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      }
    });
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      searchval: "",
    },
    validationSchema: ipAdd,
    onSubmit: (values, action) => {
      addIp(values, action);
    },
  });

  const changeStatus = async (id) => {
    try {
      setLoading(true);
      await AdminListService.changeIpStatus(id);

      ipList();
    } catch (err) {
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.warn(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No.
              </th>
              <th
                scope="col"
                onClick={() => requestSort("ip_address")}
                className={getClassNamesFor("ip_address")}
              >
                IP Address
              </th>

              <th
                scope="col"
                onClick={() => requestSort("register_status")}
                className={getClassNamesFor("register_status")}
              >
                Registration Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{el.ip_address}</td>

                  <td className="ip-status">
                    <label className="switch switch-green">
                      <input
                        type="checkbox"
                        className="switch-input"
                        defaultChecked={
                          el.register_status === "on" ? true : false
                        }
                        onChange={() => {
                          changeStatus(el.id);
                        }}
                      />
                      <span
                        className="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <button
                        type="button"
                        className="text-danger open-model-btn"
                        title="De Activate"
                        onClick={() => {
                          delAcc(el.id);
                        }}
                      >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Add IP Address</h5>
            </div>
            <div className="card-body">
              <form className="" onSubmit={handleSubmit} noValidate>
                <div className="col-lg-4 ps-lg-0">
                  <div className="input-field-box mb-lg-0">
                    <label>IP Address</label>
                    <div className="input-group">
                      <input
                        type={"text"}
                        className={`form-control ${
                          errors.searchval && touched.searchval
                            ? "is-danger"
                            : ""
                        }`}
                        onChange={handleChange}
                        name="searchval"
                        value={values.searchval || ""}
                        required
                      />

                      <button className="btn btn-primary" type="submit">
                        Add IP
                      </button>
                    </div>
                  </div>
                  {errors.searchval && touched.searchval ? (
                    <span className="text-danger">{errors.searchval}</span>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Registered IPs [{tableData.length}]
              </h5>
            </div>

            <div className="card-body">
              <div className="clearfix"></div>

              <Table data={tableData} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
