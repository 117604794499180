import React from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { Helmet } from "react-helmet";

export default function Sidebar() {
  const locate = useLocation();
  const menuBtn = () => {
    $(".site-main-design").toggleClass("full-show");
    $(".site-footer-main").toggleClass("full-show");
  };
  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} Admin</title>
      </Helmet>
      <div
        id="sidebar"
        className="collapse collapse-horizontal show border-end"
      >
        <div
          id="sidebar-nav"
          className="list-group border-0 rounded-0 text-sm-start min-vh-100"
        >
          <Link
            to="/admin_dashboard"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/admin_dashboard" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-house-fill"></i> <span> Dashboard</span>{" "}
          </Link>
          <Link
            to="/registered-ips"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/registered-ips" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-key"></i> <span> Registered IPs</span>{" "}
          </Link>
          <Link
            to="/registered-users"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/registered-users" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-person"></i> <span> Registered Users</span>{" "}
          </Link>

          <Link
            to="/site_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/site_manage" ||
              locate.pathname === "/site_add"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-globe2"></i> <span> Manage Customers</span>
          </Link>
          <Link
            to="/sub_company"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/sub_company" ||
              locate.pathname === "/add_sub_company"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-globe2"></i> <span> Manage Sub Companies</span>
          </Link>
          <Link
            to="/pickyourplastic_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/pickyourplastic_manage" ||
              locate.pathname === "/pickyourplastic_add_site"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-globe2"></i>{" "}
            <span> Manage {process.env.REACT_APP_API_SITE_TITLE} Sites</span>
          </Link>
          <Link
            to="/osr_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/osr_manage" ||
              locate.pathname === "/osr_add"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-globe2"></i> <span> Manage OSR</span>
          </Link>
          <Link
            to="/acc_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/acc_manage" ||
              locate.pathname === "/acc_add"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-globe2"></i> <span> Manage Accountants</span>
          </Link>
          <Link
            to="/rep_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/rep_manage" ||
              locate.pathname === "/rep_add"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-globe2"></i> <span> Manage CSR </span>
          </Link>
          <Link
            to="/Managestatus"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/Managestatus" ||
              locate.pathname === "/Addpav"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-image-fill"></i>{" "}
            <span> Manage Pavlina Section</span>
          </Link>
          <Link
            to="/lookup_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/lookup_manage" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-globe2"></i>{" "}
            <span> Manage {process.env.REACT_APP_SITE_NAME} Lookup </span>
          </Link>
          <Link
            to="/card_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/card_manage" ||
              locate.pathname === "/card_add"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-image"></i> <span> Manage Cards</span>{" "}
          </Link>

          <Link
            to="/ManageAdmin"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/ManageAdmin" ||
              locate.pathname === "/AddAdmin"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-person-circle"></i> <span> Manage Admins</span>{" "}
          </Link>

          {/* <Link
            to="/OldFile"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/OldFile" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-upload"></i> <span> Upload old File </span>{" "}
          </Link> */}

          <Link
            to="/job_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/job_manage" ||
              locate.pathname === "/job_add"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-file-bar-graph"></i> <span> Mange Jobs</span>{" "}
          </Link>

          <Link
            to="/certificat_details"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/certificat_details" ||
              locate.pathname === "/certdetail"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-eye-fill"></i>{" "}
            <span> View Certificate Details</span>{" "}
          </Link>

          {/* <Link
            to="/export_sendmail"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/export_sendmail" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-person-plus-fill"></i>{" "}
            <span> Export By Mail</span>{" "}
          </Link> */}

          <Link
            to="/manage_mails"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/manage_mails" ||
              locate.pathname === "/sendmail"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-cart-fill"></i> <span> Manage Mails</span>{" "}
          </Link>

          <Link
            to="/random_cert"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/random_cert" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-card-checklist"></i>{" "}
            <span> Generate Random Certificate</span>{" "}
          </Link>

          <Link
            to="/manage_by_company"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/manage_by_company" ||
              locate.pathname === "/job_add_by_company"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-card-checklist"></i>{" "}
            <span> Manage Cards by Customer</span>{" "}
          </Link>

          <Link
            to="/manage_by_job"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/manage_by_job" ||
              locate.pathname === "/job_add_by_job"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-card-checklist"></i>{" "}
            <span> Manage Cards by Job</span>{" "}
          </Link>

          <Link
            to="/dell_all"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/dell_all" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-trash"></i>{" "}
            <span> Manage Delete Users By Upload</span>{" "}
          </Link>

          <Link
            to="/state_manage"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/state_manage" ||
              locate.pathname === "/state_add"
                ? "active"
                : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-flag"></i> <span> Manage States</span>{" "}
          </Link>

          <Link
            to="/cert-record"
            className={`list-group-item border-end-0 d-inline-block text-truncate ${
              locate.pathname === "/cert-record" ? "active" : ""
            }`}
            data-bs-parent="#sidebar"
          >
            <i className="bi bi-clipboard-data"></i>{" "}
            <span> Certificate Record</span>{" "}
          </Link>
        </div>
      </div>

      <button
        id="menu-open-close"
        onClick={menuBtn}
        data-bs-target="#sidebar"
        data-bs-toggle="collapse"
        className="border rounded-1 p-1 text-decoration-none"
      >
        <i className="bi bi-list bi-lg py-2 p-1"></i> Menu
      </button>
      <br />
      <br />
    </>
  );
}
